<template>
  <div class="page-single">

  </div>
</template>

<script>
export default {
  name: 'Logout',

  data() {
    return {
      form: {
        code: '1401952',
        password: '123123',
      },
    };
  },

  created() {
    this.logout();
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style scoped>
  .page-login {
    width: 100%;
  }
</style>
